<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.verwendungKostenstelle.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.verwendungKostenstelle.list.title"></app-i18n>
      </h1>

      <app-verwendung-kostenstelle-list-toolbar></app-verwendung-kostenstelle-list-toolbar>
      <app-verwendung-kostenstelle-list-filter></app-verwendung-kostenstelle-list-filter>
      <app-verwendung-kostenstelle-list-table></app-verwendung-kostenstelle-list-table>
    </div>
  </div>
</template>

<script>
import MandantTheatigkeitListFilter from '@/modules/verwendung-kostenstelle/components/verwendung-kostenstelle-list-filter.vue';
import MandantTheatigkeitListTable from '@/modules/verwendung-kostenstelle/components/verwendung-kostenstelle-list-table.vue';
import MandantTheatigkeitListToolbar from '@/modules/verwendung-kostenstelle/components/verwendung-kostenstelle-list-toolbar.vue';

export default {
  name: 'app-verwendung-kostenstelle-list-page',

  components: {
    [MandantTheatigkeitListFilter.name]: MandantTheatigkeitListFilter,
    [MandantTheatigkeitListTable.name]: MandantTheatigkeitListTable,
    [MandantTheatigkeitListToolbar.name]: MandantTheatigkeitListToolbar,
  },
};
</script>

<style></style>
